import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import config from '../config';
import ProcessingOverlay from './ProcessingOverlay';

class CreditCardPayment extends Component {
    constructor(props) {
        super(props);
  
        this.state = {
            certificateName: this.props.parentState.certificate.nameOnCreditClaim,
            totalCost: this.props.parentState.certificate.totalCost,
            cardName: "",
            address: "",
            city: "",
            stateProvince: "",
            postalCode: "",
            country: "",
            isProcessing: false,
            nonManagedItemModified: 0,
            previousStep: false,
            thankYou: false
        }

        //Load the next 10 years into an array for the expiration date selection list.
        const year = (new Date()).getFullYear();
        this.years = Array.from(new Array(10),(val, index) => index + year);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        //Load the Authorize.net Accept.js script
        const authorize_script = document.createElement("script");
        authorize_script.async = true;
        authorize_script.src = config.environment.authorizeNet.url;
        authorize_script.charset = "utf-8";
        authorize_script.type = "text/javascript";

        //Append the Accept.js script to the header.
        document.head.appendChild(authorize_script);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    previousStep = (e) => {
        e.preventDefault();
        this.setState({previousStep: true});
    }

    isFieldValid(field_name) {
        var is_valid = false;

        switch (field_name) {
            case "cardName":
                is_valid = (this.state.cardName !== "");
                break;
            case "cardNumber":
                is_valid = (this.nonManagedFieldValue(field_name) !== "");
                break;
            case "expirationMonth":
                is_valid = (this.nonManagedFieldValue(field_name) !== "");
                break;
            case "expirationYear":
                is_valid = (this.nonManagedFieldValue(field_name) !== "");
                break;
            case "cvv":
                is_valid = (this.nonManagedFieldValue(field_name) !== "");
                break;
            case "postalCode":
                is_valid = (this.state.postalCode !== "");
                break;
            default:
                console.log(field_name + " was not found");
        }

        return is_valid;
    }

    nonManagedFieldValue(field_name) {
        var non_managed_field = window.document.getElementById(field_name);

        if (non_managed_field === null) {
            return "";
        }
        else {
            return non_managed_field.value;
        }
    }

    nonManagedOnChange = (event) => {
        document.getElementById(event.target.name).className = document.getElementById(event.target.name).className.replace( /(?:^|\s)validationError(?!\S)/g , '' );

        if (!this.isFieldValid(event.target.name)) {
            document.getElementById(event.target.name).className += " validationError";           
        }

        this.setState({nonManagedItemModified: this.state.nonManagedItemModified + 1});
    }

    isFormValid() {
        var is_form_valid = (
            this.isFieldValid("cardName") && 
            this.isFieldValid("cardNumber") &&
            this.isFieldValid("expirationMonth") &&
            this.isFieldValid("expirationYear") &&
            this.isFieldValid("cvv") &&
            this.isFieldValid("postalCode")
        );

        return is_form_valid;
    }

    sendPaymentDataToAnet = (event) => {
        event.preventDefault();
        this.sendPaymentDataToAnetWorker();
    }

    async sendPaymentDataToAnetWorker() {
        this.setState({isProcessing: true});

        var authData = {};
        authData.clientKey = config.environment.authorizeNet.publicKeyCode;
        authData.apiLoginID = config.environment.authorizeNet.loginID;

        var cardData = {};
        cardData.cardNumber = window.document.getElementById("cardNumber").value.trim();// this.state.cardNumber;
        cardData.month = window.document.getElementById("expirationMonth").value;//this.state.expirationMonth;
        cardData.year = window.document.getElementById("expirationYear").value;//this.state.expirationYear;
        cardData.cardCode = window.document.getElementById("cvv").value.trim();//this.state.cvv;

        var secureData = {};
        secureData.authData = authData;
        secureData.cardData = cardData;

        //Call Authorize.Net to get a token for the card.  This will run from their library, so we've had to take extra measures to get it to work.
        this.dispatchData(secureData);

        //This will only work if the flag is set as having been completed.  The isFormReadyToContinue() method will wait until that flag is set, allowing us to continue.
        await this.isFormReadyToContinue();

        //Reset our flag.  If there were errors, we will begin anew.
        window.document.getElementById("callbackCompleted").value = "";

        if (window.document.getElementById("dataDescriptor").value === "")
        {
            //There was an error, so exit this function and don't complete wiping out of values.
            this.setState({isProcessing: false});
            return;
        }

        //The form is ready to be sent to the server for processing.
        this.makePayment();
    }

    dispatchData(secureData) {
        window.document.getElementById("callbackCompleted").value = "0";

        window.Accept.dispatchData(secureData, function responseHandler(response) {
            //Make sure no previous errors show up.
            var transaction_message_div = document.getElementById("transactionMessage");
            transaction_message_div.innerHTML = "";
            transaction_message_div.style = "display: none";
    
            if (response.messages.resultCode === "Error")
            {
                var i = 0;
                var error_message = "";
    
                while (i < response.messages.message.length) {
                    error_message += response.messages.message[i].text + "<br />\n";
                    i = i + 1;
                }

                //Show the error messages.
                transaction_message_div = document.getElementById("transactionMessage");
                transaction_message_div.innerHTML = error_message;
                transaction_message_div.style = "display: block";

                //Set the marker that this is now complete.
                document.getElementById("callbackCompleted").value = "1";
            }
            else
            {
                //Transaction went through, continue processing.
                document.getElementById("dataDescriptor").value = response.opaqueData.dataDescriptor;
                document.getElementById("dataValue").value = response.opaqueData.dataValue;
                
                //Blank out the fields before submitting them to the server.
                document.getElementById("cardNumber").value = "";
                document.getElementById("expirationMonth").value = "";
                document.getElementById("expirationYear").value = "";
                document.getElementById("cvv").value = "";

                //Set the marker that this is now complete.
                document.getElementById("callbackCompleted").value = "1";
            }
        });
    }

    async isFormReadyToContinue() {
        var wait = ms => new Promise((r, j)=>setTimeout(r, ms));
        
        //Hack this together to wait for the error to load or the opaque data to load.
        while (window.document.getElementById("callbackCompleted").value !== "1")
        {
            await wait(200);
        }
    }
    
    async makePayment() {
        let url = config.environment.application.baseUrl + "/api/creditclaim/" + this.props.parentState.certificate.id + "/payment";
    
        var payment_form = {
            name: this.state.cardName,
            address: this.state.address,
            city: this.state.city,
            stateProvince: this.state.stateProvince,
            postalCode: this.state.postalCode,
            country: this.state.country,
            dataDescriptor: window.document.getElementById("dataDescriptor").value,
            dataValue: window.document.getElementById("dataValue").value,
            creditClaimHash: this.props.parentState.certificate.creditClaimHash
        }

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(payment_form)
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            //Check to see if we have a completed payment
            if (data.wasSuccessful) {
                this.props.parentState.transactionId = data.transactionId;
                this.setState({thankYou: true});
            }
            else {
                var transaction_message_div = window.document.getElementById("transactionMessage");
                transaction_message_div.innerHTML = data.errorMessage;
                transaction_message_div.style = "display: block";
            }
        })
        .catch((error) => {console.log(error);});

        if (this.props.parentState.transactionId === "") {
            //Reset the authorize.net values and start again if staying on this page.
            this.setState({isProcessing: false});
            window.document.getElementById("dataDescriptor").value = "";
            window.document.getElementById("dataValue").value = "";
        }
    }

    render() {
        if (this.state.previousStep || this.props.parentState.certificate.nameOnCreditClaim === "")
            return (<Redirect to="step3" />);
        
        if (this.state.thankYou)
            return (<Redirect to="thankyou" />);

        return (
            <div>            
                <ProcessingOverlay isProcessing={this.state.isProcessing} />  
                <div className="row">
                <div className="col-xs-12 col-sm-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-8 offset-md-2">
                            <h5><b>Credit Card Payment</b></h5>
                            Please fill out your Billing Address and Credit Card information below.  We accept <strong>MasterCard</strong>, <strong>Visa</strong>, <strong>American Express</strong>, and <strong>Discover</strong>.<br />
                            <br />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-8 offset-md-2">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-6">
                                    <b>Name on Certificate</b><br />
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6">
                                    {this.state.certificateName}<br />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-8 offset-md-2">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6 col-md-6">
                                    <b>Total Amount Due</b><br />
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-6">
                                    ${this.state.totalCost}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-8 offset-md-2">
                            <br />
                            <div id="transactionMessage" className="alert alert-danger" style={{display: "none"}}></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-8 offset-md-2">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <b>Name on Credit Card</b><br />
                                    <input type="text" className={"form-control " + (!this.isFieldValid("cardName") ? "validationError" : "")} name="cardName" value={this.state.cardName} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <b>Credit Card Number</b><br />
                                    <input type="text" className={"form-control " + (!this.isFieldValid("cardNumber") ? "validationError" : "")} name="cardNumber" id="cardNumber" onChange={this.nonManagedOnChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-9">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12">
                                                    <b>Card Expiration Date</b><br />
                                                </div>
                                                <div className="col-xs-6 col-sm-6 col-md-6">
                                                    <select className={"form-control " + (!this.isFieldValid("expirationMonth") ? "validationError" : "")} name="expirationMonth" id="expirationMonth" onChange={this.nonManagedOnChange}>
                                                        <option value="">Month</option>
                                                        <option value="01">01</option>
                                                        <option value="02">02</option>
                                                        <option value="03">03</option>
                                                        <option value="04">04</option>
                                                        <option value="05">05</option>
                                                        <option value="06">06</option>
                                                        <option value="07">07</option>
                                                        <option value="08">08</option>
                                                        <option value="09">09</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </div>
                                                <div className="col-xs-6 col-sm-6 col-md-6">
                                                    <select className={"form-control " + (!this.isFieldValid("expirationYear") ? "validationError" : "")} name="expirationYear" id="expirationYear" onChange={this.nonManagedOnChange}>
                                                        <option value="">Year</option>
                                                        {
                                                            this.years.map((year, index) => {
                                                                return <option key={`year${index}`} value={year}>{year}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-3">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12">
                                            <b>CVV</b><br />
                                            <input type="text" className={"form-control " + (!this.isFieldValid("cvv") ? "validationError" : "")} name="cvv" id="cvv" onChange={this.nonManagedOnChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <b>Address</b><br />
                                    <input type="text" className="form-control" name="address" value={this.state.address} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-4">
                                    <b>City</b><br />
                                    <input type="text" className="form-control" name="city" value={this.state.city} onChange={this.handleChange} />
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-4">
                                    <b>State/Province</b><br />
                                    <input type="text" className="form-control" name="stateProvince" value={this.state.stateProvince} onChange={this.handleChange} />
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-4">
                                    <b>Postal Code</b><br />
                                    <input type="text" className={"form-control " + (!this.isFieldValid("postalCode") ? "validationError" : "")} name="postalCode" value={this.state.postalCode} onChange={this.handleChange} />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12">
                                    <b>Country</b><br />
                                    <input type="text" className="form-control" name="country" value={this.state.country} onChange={this.handleChange} />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <button className="btn btn-primary" disabled={this.state.isProcessing} style={{width: "100%"}} onClick={this.previousStep}>Previous Page</button><br />
                                    <br />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <button className="btn btn-primary" disabled={!this.isFormValid() || this.state.isProcessing} style={{width: "100%"}} onClick={this.sendPaymentDataToAnet}>Purchase</button><br />
                                    <br />
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
                <input type="hidden" id="forceFormValidation" value={this.state.nonManagedItemModified} />
                <input type="hidden" id="dataDescriptor" value="" />
                <input type="hidden" id="dataValue" value="" />
                <input type="hidden" id="callbackCompleted" value="" />
                </div>
            </div>
        );
    }
}

export default CreditCardPayment;