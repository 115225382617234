import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class ThankYou extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.parentState.certificate.id, 
            nameOnCreditClaim: this.props.parentState.certificate.nameOnCreditClaim,
            totalCost: this.props.parentState.certificate.totalCost,
            selectedAwardYear: this.props.parentState.selectedAwardYear,
            selectedHistoryItem: this.props.parentState.selectedHistoryItem,
            paymentMethod: this.props.parentState.paymentMethod,
            transactionId: this.props.parentState.transactionId
        }
    }

    selectedAwardYear() {
        for (var x = 0; x < this.props.parentState.awardYears.length; x++) {
            if (this.props.parentState.awardYears[x].awardYearId.toString() === this.state.selectedAwardYear.toString()) {
                return this.props.parentState.awardYears[x].name;
            }
        }
    }

    isCreditCardPayment() {
        console.log(this.state.paymentMethod + ' is the payment method');
        return (this.state.paymentMethod === "credit_card");
    }

    isCheckPayment() {
        return (this.state.paymentMethod === "check");
    }

    thankYouMessage() {
        if (this.isCreditCardPayment()) {
            return (
                <div>
                    Your request has been completed.  Please print this page for your records.<br />
                </div>
            );
        }
        else {
            return (
                <div>
                    Your request has been completed.  Please print this page for your records.  You will need to mail in this form with your payment.  Please allow 8-10 weeks from the time the check is received at the Recording Academy<sup>&reg;</sup> for delivery.<br />
                    <br />
                    <b>Return this printed page with your check or money order.</b><br />
                    <br />
                    Recording Academy<br />
                    c/o Awards Department - Participation Certificates<br />
                    3030 Olympic Boulevard<br />
                    Santa Monica, CA 90404<br />
                </div>
            );
        }
    }

    paymentMethod() {
        if (this.isCreditCardPayment())
            return "Credit Card";
        else
            return "Check";
    }

    creditCardTransactionID() {
        if (this.isCreditCardPayment()) {
            return (<div className="col-xs-12 col-sm-5">
                        <b>Credit Card Transaction</b><br />
                        {this.state.transactionId}<br />
                        <br />
                    </div>
            );
        }
        else
            return "";
    }

    render() {
        if (this.state.transactionId === "")
            return (<Redirect to="payment" />);

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                        {this.thankYouMessage()}
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xs-12 col-sm-5">
                        <b>Order ID</b><br />
                        {this.state.id}<br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-7">
                        <b>Name on Credit</b><br />
                        {this.state.nameOnCreditClaim}<br />
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-5">
                        <b>Order Amount</b><br />
                        ${this.state.totalCost}<br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-7">
                        <b>Award Year</b><br />
                        {this.selectedAwardYear()}<br />
                        <br />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-5">
                        <b>Payment Method</b><br />
                        {this.paymentMethod()}<br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-7">
                        <b>Category</b><br />
                        {this.state.selectedHistoryItem.categoryName}<br />
                        <br />
                    </div>
                </div>
                <div className="row">
                    {this.creditCardTransactionID()}
                    <div className="col-xs-12 col-sm-7">
                        <b>Nominated Entry</b><br />
                        {this.state.selectedHistoryItem.displayLine1}{this.state.selectedHistoryItem.displayLine2 != null ? <br /> : null}
                        {this.state.selectedHistoryItem.displayLine2}{this.state.selectedHistoryItem.displayLine3 != null ? <br /> : null}
                        {this.state.selectedHistoryItem.displayLine3}<br />
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}

export default ThankYou;