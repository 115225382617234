import React, { Component } from 'react';
import config from '../config';
import { Redirect } from 'react-router-dom';

class Step1 extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            nextStep: false
        }
    }

    nextStep = (e) => {
        this.setState({nextStep: true});
    }

    render() {
        if (this.state.nextStep)
            return (<Redirect push to='/step2' />);

        return (
            <div>
                {
                    (config.environment.authorizeNet.keyCode === "prod") ? null : 
                        <h1 style={{color: "red"}}>{config.environment.authorizeNet.keyCode} {config.environment.authorizeNet.keyCode} {config.environment.authorizeNet.keyCode} {config.environment.authorizeNet.keyCode}</h1>
                }

                <p>
                Welcome to the GRAMMY Credit and Nomination Corrections Page.  The Recording Academy has implemented new administration guidelines regarding requests to change credit information for GRAMMY-nominated or GRAMMY-winning projects.  Our full policy can be found <a target="_blank" href="https://grammysubmit.dmds.com/Content/documents/naras/en/CreditNominationCorrectionPolicy.pdf">HERE</a>.  All credit and nomination correction requests are subject to a full review by Academy Leadership.
                </p>

                <h5>CORRECTION REQUESTS AFTER NOMINATIONS ANNOUNCEMENT</h5>
                <p>
                    <strong>After the nominations announcement, typically in mid-November or early December</strong>, you may request changes in the original credits within two weeks after the announcement, with the following required documentation and fees:
                </p>
                <ol type="a">
                    <li>A formal statement from the head of the label, content owner or producer on behalf of the content owner, specifically detailing what was inaccurately submitted or omitted, an explanation as to why the error took place, and what steps are being taken to correct the mistake.</li>
                    <li>A notarized statement from the artist, where applicable.</li>
                    <li>Submit updated (final) label copy or credit list approved by content owner or producer on behalf of the content owner, where applicable.</li>
                    <li>Call sheets or studio logs proving the additional person or persons have worked on the recording and their roles, where applicable.</li>
                    <li><strong>There will be a fee of $200 per added name. This fee is to be paid in advance of the adjustments by the party requesting the changes.</strong></li>
                    <li>Confirmation that the credits have been updated across DSPs as applicable.</li>
                </ol>

                <h5>CORRECTION REQUESTS AFTER THE TELECAST</h5>

                <p>Any individual not included in the Final Nominations List who claims a nomination after the two-week deadline will be informed by staff that all official changes to be made will be reviewed and processed after the awards telecast. There will be a deadline of one year from the date of the telecast to make requests to the Awards Department for crediting changes/additions to nominated or winning albums. The requirements and fees are the same as above.</p>

                <p>Thank you for your participation in the GRAMMY Awards process.</p>

                <p>&nbsp;</p>

                <p><input type="button" onClick={this.nextStep} className="btn btn-primary form-control" value="Proceed" /></p>

            </div>
        );
    }
}

export default Step1;