import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import rolesJson from '../data/roles.json';
import config from '../config';
import ProcessingOverlay from './ProcessingOverlay';

class Step3 extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.parentState.certificate.id, 
            name: this.props.parentState.certificate.name,
            email: this.props.parentState.certificate.email,
            phone: this.props.parentState.certificate.phone,
            address1: this.props.parentState.certificate.address1,
            address2: this.props.parentState.certificate.address2,
            address3: this.props.parentState.certificate.address3,
            addressUnitNumber: this.props.parentState.certificate.addressUnitNumber,
            city: this.props.parentState.certificate.city,
            stateProvinceId: this.props.parentState.certificate.stateProvinceId,
            postalCode: this.props.parentState.certificate.postalCode,
            countryId: this.props.parentState.certificate.countryId,
            totalCost: this.props.parentState.certificate.totalCost,
            participationDescription: this.props.parentState.certificate.participationDescription,
            participationNotes: this.props.parentState.certificate.participationNotes,
            creditClaimHash: this.props.parentState.certificate.creditClaimHash,
            firstSelectedFile: this.props.parentState.certificate.firstSelectedFile,
            secondSelectedFile: this.props.parentState.certificate.secondSelectedFile,
            selectedFileError: "",
            nameOnCreditClaim: this.props.parentState.certificate.nameOnCreditClaim,
            certificateRoles: [],
            isProcessing: false,
            previousStep: false,
            payByCreditCard: false,
            payByCheck: false,

            countryArray: [],
            stateArray: [],
            academyId: config.environment.application.academyId
        }

        this.handleChange = this.handleChange.bind(this);

    }
    
    updateParentState() {
        this.props.parentState.certificate.id = this.state.id;
        this.props.parentState.certificate.name = this.state.name;
        this.props.parentState.certificate.email = this.state.email;
        this.props.parentState.certificate.phone = this.state.phone;
        this.props.parentState.certificate.address1 = this.state.address1;
        this.props.parentState.certificate.address2 = this.state.address2;
        this.props.parentState.certificate.address3 = this.state.address3;
        this.props.parentState.certificate.addressUnitNumber = this.state.addressUnitNumber;
        this.props.parentState.certificate.city = this.state.city;
        this.props.parentState.certificate.stateProvinceId = this.state.stateProvinceId;
        this.props.parentState.certificate.postalCode = this.state.postalCode;
        this.props.parentState.certificate.countryId = this.state.countryId;
        this.props.parentState.certificate.totalCost = this.state.totalCost;
        this.props.parentState.certificate.participationDescription = this.state.participationDescription;
        this.props.parentState.certificate.participationNotes = this.state.participationNotes;
        this.props.parentState.certificate.firstSelectedFile = this.state.firstSelectedFile;
        this.props.parentState.certificate.secondSelectedFile = this.state.secondSelectedFile;
        this.props.parentState.certificate.creditClaimHash = this.state.creditClaimHash;
        this.props.parentState.certificate.nameOnCreditClaim = this.state.nameOnCreditClaim;
        this.props.parentState.certificate.academyId = this.state.academyId;
    }

    async componentDidMount() {

        this._isMounted = true;

        let roles_json = rolesJson.map(current_role => { return {value: current_role.name, display: current_role.name} });
        this.setState({ certificateRoles: [{value: '', display: 'Please select your role...'}].concat(roles_json) });

        await import('../data/countries.json').then(country_data => {
            if (this._isMounted) {
                this.setState({countryArray: country_data.default.map(current_object => { return current_object })});
            }
        });

        await import('../data/states.json').then(state_data => {
            if (this._isMounted) {
                this.setState({stateArray: state_data.default.map(current_object => { return current_object })});
            }
        });
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });

        switch (event.target.name) {
            case "countryId":
                this.setState({stateProvinceId: ""});
                break;
            default:
                break;
        }
    }
   
    isFieldValid(field_name) {
        var is_valid = false;

        switch (field_name) {
            case "name":
                is_valid = (this.state.name !== "");
                break;
            case "nameOnCreditClaim":
                is_valid = (this.state.nameOnCreditClaim !== "");
                break;
            case "email":
                //https://www.regular-expressions.info/email.html
                is_valid = (this.state.email !== "" && (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.email)));
                break;
            case "phone":
                is_valid = (this.state.phone !== "");
                break;
            case "address1":
                is_valid = (this.state.address1 !== "");
                break;
            case "city":
                is_valid = (this.state.city !== "");
                break;
            case "stateProvince":
                is_valid = 
                    (this.state.stateProvinceId !== undefined && this.state.stateProvinceId !== "") || 
                    (
                        (this.state.countryId !== undefined && this.state.countryId !== "") &&
                        (this.statesForCountry().length === 0)
                    )
                     
                ; 
                break;
            case "postalCode":
                is_valid = (this.state.postalCode !== "");
                break;
            case "country":
                is_valid = (this.state.countryId !== undefined && this.state.countryId !== "");
                break;
            case "participationDescription":
                is_valid = (this.state.participationDescription !== "");
                break;
            case "firstSelectedFile":
                is_valid = (this.state.firstSelectedFile !== null);
            case "secondSelectedFile":
                is_valid = (this.state.secondSelectedFile !== null);
                break;
            default:
                console.log(field_name + " was not found");
        }

        return is_valid;
    }

    isFormValid() {
        var is_form_valid = (
            this.isFieldValid("name") && 
            this.isFieldValid("nameOnCreditClaim") && 
            this.isFieldValid("email") &&
            this.isFieldValid("phone") &&
            this.isFieldValid("address1") &&
            this.isFieldValid("city") &&
            this.isFieldValid("stateProvince") &&
            this.isFieldValid("postalCode") &&
            this.isFieldValid("country") &&
            this.isFieldValid("participationDescription") &&
            this.isFieldValid("firstSelectedFile") &&
            this.isFieldValid("secondSelectedFile")
        );

        return is_form_valid;
    }

    statesForCountry() {
        let state_array = [];
        var country_id = parseInt(this.state.countryId);

        if (this.state.stateArray.length > 0) {
            for (var x = 0; x < this.state.stateArray.length; x++) {
                var temp_state = this.state.stateArray[x];

                if (temp_state.countryId === country_id)
                    state_array.push(temp_state);
            }
        }

        return state_array;
    }

    chooseFirstFile = (e) => {
        window.document.getElementById("firstFile").click();
    }

    chooseSecondFile = (e) => {
        window.document.getElementById("secondFile").click();
    }

    firstFileUploadHandler = (event) => {
        let first_selected_file = event.target.files[0];
        this.setState({ selectedFileError: "" });

        if (first_selected_file === undefined) {
            //They clicked cancel.  Let's do nothing here.
        }
        else if (first_selected_file.size > config.MAX_ATTACHMENT_SIZE)
        {
            //File too large!
            event.target.value = "";
            this.setState({ firstSelectedFile: null });
            this.setState({ selectedFileError: "The maximum file size is 25MB" });
        }
        else if (!/(\.pdf|\.doc|\.docx|\.jpg|\.gif|\.png)$/i.test(first_selected_file.name)) {
            //Bad file extension.
            event.target.value = "";
            this.setState({ firstSelectedFile: null });
            this.setState({ selectedFileError: "Valid file types are: PDF, DOC, DOCX, JPG, GIF, PNG" });
		}
        else {
            //It's a good size and file extension.
            this.setState({ firstSelectedFile: first_selected_file });
        }
    }

    secondFileUploadHandler = (event) => {
        let second_selected_file = event.target.files[0];
        this.setState({ selectedFileError: "" });

        if (second_selected_file === undefined) {
            //They clicked cancel.  Let's do nothing here.
        }
        else if (second_selected_file.size > config.MAX_ATTACHMENT_SIZE)
        {
            //File too large!
            event.target.value = "";
            this.setState({ secondSelectedFile: null });
            this.setState({ selectedFileError: "The maximum file size is 25MB" });
        }
        else if (!/(\.pdf|\.doc|\.docx|\.jpg|\.gif|\.png)$/i.test(second_selected_file.name)) {
            //Bad file extension.
            event.target.value = "";
            this.setState({ secondSelectedFile: null });
            this.setState({ selectedFileError: "Valid file types are: PDF, DOC, DOCX, JPG, GIF, PNG" });
        }
        else {
            //It's a good size and file extension.
            this.setState({ secondSelectedFile: second_selected_file });
        }
    }

    previousStep = (e) => {
        e.preventDefault();
        this.updateParentState();
        this.setState({previousStep: true});
    }

    makePaymentByCheck = (e) => {
        e.preventDefault();
        this.updateParentState();
        
        this.saveCertificateToDatabase().then((response) => {
            if (response) {
                this.props.parentState.paymentMethod = "check";
                this.setState({payByCheck: true});
            }
        });
    }

    makePaymentByCreditCard = (e) => {
        e.preventDefault();
        this.updateParentState();
        
        this.saveCertificateToDatabase().then((response) => {
            if (response) {
                this.props.parentState.paymentMethod = "credit_card";
                this.setState({payByCreditCard: true});
            }
        });
    }

    async saveCertificateToDatabase() {
        this.setState({isProcessing: true});
        let return_value = true;
        let url = config.environment.application.baseUrl + "/api/creditclaim/";

        await fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(this.props.parentState.certificate)
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            this.setState({
                id: data.creditClaimId,
                creditClaimHash: data.creditClaimHash
            });
        })
        .then((data) => {
            this.props.parentState.certificate.id = this.state.id;
            this.props.parentState.certificate.creditClaimHash = this.state.creditClaimHash;
        })
        .catch((error) => console.log(error));

        if (!(this.state.id > 0))
            return_value = false;

        //Now, upload the supporting file
        const data = new FormData()
        data.append('firstFile', this.state.firstSelectedFile);
        data.append('secondFile', this.state.secondSelectedFile);
        data.append('creditClaimHash', this.state.creditClaimHash);
        if (return_value) {
            //Modify the URL to upload the file.
            url += this.state.id + "/uploadFile";

            await fetch(url, {
                method: 'POST',
                body: data
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status !== 'success')
                    return_value = false;
            })
            .catch((error) => console.log(error));
        }

        this.setState({isProcessing: false});

        return return_value;
    }

    render() {

        // Catch previous orders
        if (this.props.parentState.transactionId && this.props.parentState.transactionId !== "") {
            this.props.parentState.transactionId = "";
            this.props.parentState.certificate.id = 0;
            // this.props.parentState.certificate.totalCost = 0;
            this.props.parentState.certificate.participationDescription ="";
            this.props.parentState.certificate.participationNotes = "";
            this.props.parentState.certificate.firstSelectedFile = null;
            this.props.parentState.certificate.secondSelectedFile = null;
            return (<Redirect push to='/step2'/>);
        }

        if (this.state.previousStep || this.props.parentState.selectedHistoryItem === "")
            return (<Redirect push to='/step2' />);

        if (this.state.payByCheck)
            return (<Redirect push to='/thankyou' />);

        if (this.state.payByCreditCard) 
            return (<Redirect push to='/payment' />);

        return (
            <div>
                <ProcessingOverlay isProcessing={this.state.isProcessing} />  
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <h1>Contact Information</h1>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4">
                        <b>Name</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("name") ? "validationError" : "")} name="name" value={this.state.name} onChange={this.handleChange} />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <b>Email</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("email") ? "validationError" : "")} name="email" value={this.state.email} onChange={this.handleChange} />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-4">
                        <b>Phone</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("phone") ? "validationError" : "")} name="phone" value={this.state.phone} onChange={this.handleChange} />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-9">
                        <b>Address 1 (No PO Boxes)</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("address1") ? "validationError" : "")} name="address1" value={this.state.address1} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-3">
                        <b>Unit/Suite #</b><br />
                        <input type="text" className={"form-control"} name="addressUnitNumber" value={this.state.addressUnitNumber} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Address 2</b><br />
                        <input type="text" className="form-control" name="address2" value={this.state.address2} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Address 3</b><br />
                        <input type="text" className="form-control" name="address3" value={this.state.address3} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Country</b><br />
                        <select name="countryId" value={this.state.countryId} onChange={this.handleChange} className={"form-control " + (!this.isFieldValid("country") ? "validationError" : "")}>
                            <option value="">Select Country...</option>
                            {
                                this.state.countryArray.map((current_object) => {
                                    return <option key={`country_${current_object.id}`} value={current_object.id}>{current_object.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-5">
                        <b>City</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("city") ? "validationError" : "")} name="city" value={this.state.city} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-8 col-md-4">
                        <b>State/Province</b><br />
                        <select name="stateProvinceId" value={this.state.stateProvinceId} onChange={this.handleChange} className={"form-control " + (!this.isFieldValid("stateProvince") ? "validationError" : "")}>
                            <option value="">Select State/Province...</option>
                            {
                                this.statesForCountry().map((current_object) => {
                                    return <option key={`state_${current_object.id}`} value={current_object.id}>{current_object.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-3">
                        <b>Postal Code</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("postalCode") ? "validationError" : "")} name="postalCode" value={this.state.postalCode} onChange={this.handleChange}  />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <h1>Credited Information</h1>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4">
                        <b>Name (as it will appear in credits)</b><br />
                        <input type="text" className={"form-control " + (!this.isFieldValid("nameOnCreditClaim") ? "validationError" : "")} name="nameOnCreditClaim" value={this.state.nameOnCreditClaim} onChange={this.handleChange} />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Please select role on this project</b> (the Recording Academy reserves the right to determine whether or not it is an eligibly-credited role)
                        <select name="participationDescription" value={this.state.participationDescription} onChange={this.handleChange} className={"form-control " + (this.state.participationDescription === "" ? "validationError" : "")}>
                            {this.state.certificateRoles.map((certificate_role) => <option key={certificate_role.value} value={certificate_role.value}>{certificate_role.display}</option>)}
                        </select>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <b>Additional Information To Support The Correction Request – Optional (500 Character Limit)</b><br />
                        <textarea className={"form-control"} name="participationNotes" value={this.state.participationNotes} onChange={this.handleChange} maxLength="500" />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <br />
                        <b>Liner Notes</b><br />
                        <input style={{display: "none"}} id="firstFile" type="file" onChange={this.firstFileUploadHandler} />
                        <input type="button" className={"btn " + (this.state.firstSelectedFile === null ? "btn-danger" : "btn-secondary")} value="Upload Liner Notes" onClick={this.chooseFirstFile} />&nbsp;&nbsp;
                        <span><b>Selected file: </b>{(this.state.firstSelectedFile === null) ? "" : this.state.firstSelectedFile.name}</span><span style={{color: "red"}}>{this.state.selectedFileError}</span><br />
                        <br />
                        Valid file types are: PDF, DOC, DOCX, JPG, GIF, PNG<br />
                        Maximum file size is 50MB.<br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <br />
                        <b>Statement or Affidavit</b><br />
                        <input style={{display: "none"}} id="secondFile" type="file" onChange={this.secondFileUploadHandler} />
                        <input type="button" className={"btn " + (this.state.secondSelectedFile === null ? "btn-danger" : "btn-secondary")} value="Upload Statement or Affidavit" onClick={this.chooseSecondFile} />&nbsp;&nbsp;
                        <span><b>Selected file: </b>{(this.state.secondSelectedFile === null) ? "" : this.state.secondSelectedFile.name}</span><span style={{color: "red"}}>{this.state.selectedFileError}</span><br />
                        <br />
                        Valid file types are: PDF, DOC, DOCX, JPG, GIF, PNG<br />
                        Maximum file size is 50MB.<br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        <b>Processing fee: ${config.environment.application.certificatePrice}</b>
                    </div>
                    <div className="col-xs-12 col-sm-12">
                        &nbsp;
                    </div>

                    <div className="col-xs-12 col-sm-6">
                        <button className="btn btn-primary" disabled={this.state.isProcessing} onClick={this.previousStep} style={{width: "100%"}}>Previous Page</button><br />
                        <br />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <button className="btn btn-primary" disabled={!this.isFormValid() || this.state.isProcessing} onClick={this.makePaymentByCreditCard} style={{width: "100%"}}>Pay now by Credit Card</button><br />
                        <br />
                    </div>
                </div>
            </div>
        );
    }
}
/*
                <div className="col-xs-12 col-sm-4">
                    <button className="btn btn-primary" disabled={!this.isFormValid() || this.state.isProcessing} onClick={this.makePaymentByCheck} style={{width: "100%"}}>Mail a Check</button><br />
                    <br />
                </div>

*/

export default Step3;