import "./ProcessingOverlay.css";

export function ProcessingOverlay({ isProcessing }) {

    if (!isProcessing) return null;

    return (
      <div className="overlay">
        <div className="spinner-border text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
}

export default ProcessingOverlay;