import React, { Component } from 'react';
import {HashRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import CreditCardPayment from './components/CreditCardPayment';
import ThankYou from './components/ThankYou';
import config from './config';
//import { Redirect } from 'react-router-dom';
//import Closed from './components/Closed'


class App extends Component {
    constructor(props) {
      super(props);

      this.state = {
          currentStep: 1,
          awardYears: [],
          selectedAwardYear: "",
          categories: [],
          selectedCategoryNumber: "",
          historyItems: [],
          selectedHistoryItem: "",
          certificate: { 
            id: 0, 
            name: "",
            email: "",
            phone: "",
            address1: "",
            address2: "",
            address3: "",
            addressUnitNumber: "",
            city: "",
            stateProvinceId: "",
            postalCode: "",
            countryId: "",
            totalCost: config.environment.application.certificatePrice,
            participationDescription: "",
            participationNotes: "",
            awardHistoryId: 0,
            firstSelectedFile: null,
            secondSelectedFile: null,
            nameOnCreditClaim: "",
            creditClaimHash: ""
          },
          academyId: config.environment.application.academyId,
          paymentMethod: "",
          transactionId: ""
      };
  }
  
  //This is the router you use while in-season.
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Step1} />
          <Route path="/step1" component={Step1} />
          <Route path="/step2" render={() => <Step2 parentState={this.state} />} />
          <Route path="/step3" render={() => <Step3 parentState={this.state} />} />
          <Route path="/payment" render={() => <CreditCardPayment parentState={this.state} />} />
          <Route path="/thankyou" render={() => <ThankYou parentState={this.state} />} />
        </Switch>
      </Router>
    )
  }
}

export default App;
