const grammyConfig = {
    prod: {
        application: {
            baseUrl: "https://grammycreditclaims-backend-prod.azurewebsites.net",
            academyId: 1,
            logo: "logo.png",
            certificatePrice: 200
        },
        authorizeNet: {
            loginID: "7JXydj78Q9",
            keyCode: "prod",
            publicKeyCode: "2vHG23q2JxFV7Up989L36hA7qdXMrwa9ea4rnm8Ev23TD9bKjVT75dXJNrs6py5u",
            developmentSite: false,
            url: "https://js.authorize.net/v1/Accept.js"
        }
    },
    qa: {
        application: {
            baseUrl: "https://participationcertificate-qa.grammy.com",
            academyId: 1,
            logo: "logo.png",
            certificatePrice: 200
        },
        authorizeNet: {
            loginID: "7NEK9tr5h",
            keyCode: "qa",
            publicKeyCode: "4GaHp84NKF83W76WF3bqbUQxmfjP86kB78P9MQz78eGdxK3jgAJksS36Edq9bXgV",
            developmentSite: true,
            url: "https://jstest.authorize.net/v1/Accept.js",
        }
    },
    dev: {
        application: {
            baseUrl: "https://localhost:7185",
            academyId: 1,
            logo: "logo.png",
            certificatePrice: 200
        },
        authorizeNet: {
            loginID: "7NEK9tr5h",
            keyCode: "dev",
            publicKeyCode: "4GaHp84NKF83W76WF3bqbUQxmfjP86kB78P9MQz78eGdxK3jgAJksS36Edq9bXgV",
            developmentSite: true,
            url: "https://jstest.authorize.net/v1/Accept.js",
        }
    }
};


//This "REACT_APP_STAGE" variable is set in the package.json -> scripts -> start command and is only set for DEV.
//When building the application for QA, there is a build-qa command. 
//Production is never set and therefor when it is not DEV or QA, it will default to PROD.
//This file needs to be imported and can be accessed like "config.environment"


// Set for GRAMMYs or Latin GRAMMYs. Default to GRAMMYs (1)
const dev  = grammyConfig.dev;
const qa   = grammyConfig.qa;
const prod = grammyConfig.prod;

const environment = (process.env.REACT_APP_STAGE === 'dev') ? dev : ((process.env.REACT_APP_STAGE === 'qa') ? qa : prod);

// environment.application.academyId = (process.env.REACT_APP_ACADEMY) ? process.env.REACT_APP_ACADEMY : 1;


export default {
// Add common config values here
    MAX_ATTACHMENT_SIZE: 26214400,
    environment
};